import React, { useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EmbedConfig } from "../models/EmbedConfig";

type PowerBIReportProps = {
  config: EmbedConfig;
};

export const PowerBIReport: React.FC<PowerBIReportProps> = ({ config }) => {
  const [isReportLoading, setIsReportLoading] = useState(true);
  const { t } = useTranslation();

  const handleLoaded = () => {
    setIsReportLoading(false);
    const iframe = document.querySelector('.report-container-2 iframe');
    if (iframe) {
      iframe.style.visibility = 'visible';
    }
  };

  return (
    <>
      {isReportLoading && (
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: config.uuid,
          pageName: config.pageName,
          embedUrl: config.url,
          accessToken: config.token,
          tokenType: models.TokenType.Embed,
          settings: {
            authoringHintsEnabled: false,
            background: models.BackgroundType.Default,
            bars: {
              statusBar: { visible: false },
              actionBar: { visible: false }
            },
            bookmarksPaneEnabled: false,
            customLayout: {
              displayOption: models.DisplayOption.FitToWidth,
            },
            filterPaneEnabled: false,
            hyperlinkClickBehavior: models.HyperlinkClickBehavior.Navigate,
            navContentPaneEnabled: false,
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            persistentFiltersEnabled: false,
            layoutType: models.LayoutType.Custom
          },
        }}
        cssClassName="report-container-2"
        eventHandlers={
          new Map([
            [
              "rendered",
              function () {
                handleLoaded();
              },
            ],
          ])
        }
      />
    </>
  );
};