import {
  Autocomplete,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { dataFromApi } from "../../redux/getApiData/reducer";
import {
  errorData,
  submitData,
  updateSubmitData,
} from "../../redux/submitData/reducer";
import { FileUploader } from "react-drag-drop-files";
import { FormProps } from "./FormProps";
import { OnBoardEmployeeFormData } from "../../models/forms/OnBoardEmployeeFormData";
import FileFieldDisplayComponent from "./FileDisplayComponent";
import dayjs from "dayjs";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
export const OnboardEmployeeForm: React.FC<FormProps> = ({
  handleOnSubmit,
  validators,
}) => {
  const apiData = useAppSelector(dataFromApi);
  const dispatch = useAppDispatch();
  const formData = useAppSelector(submitData);
  const formFieldData = formData?.data as OnBoardEmployeeFormData;
  const formErrorData = useAppSelector(errorData);
  const keyboardLayout = ["UK", "CH", "US", "Other"];
  const [selectedKeyBoardLayout, setKeyboardLayout] = useState("");
  const { t } = useTranslation();
  const [noteLabel, setNoteLabel] = useState("noteOnBoardTextFieldPlaceHolder")
  const uniqueUsers = apiData?Array.from(new Set(apiData.tenant.users)) : [];
  function handleSubmitData() {
    var nameNotValidated = !(
      validators.requiredValidator("name", formFieldData?.name) &&
      validators.minLengthValidator("name", formFieldData?.name, 3)
    );
    var jobTitleNotValidated = !validators.requiredValidator(
      "jobTitle",
      formFieldData?.jobTitle
    );
    var managerNotValidated = !validators.requiredValidator(
      "manager",
      formFieldData?.manager
    );
    var fileNotValidated = false;
    var useMultipart = false;
    if (formFieldData?.attachment) {
      let totalSize = 0;
      for (const fileElement of formFieldData?.attachment) {
        totalSize = totalSize + fileElement.size;
      }
      fileNotValidated = !(
        validators.maxLengthValidator(
          "attachment",
          formFieldData?.attachment,
          4,
          "numberOfFileError"
        ) &&
        validators.fileSizeValidator("attachment", formFieldData?.attachment)
      );
      useMultipart = true;
    }
    var keyboardLayoutNotValidated = false
    if (selectedKeyBoardLayout === "Other") {
      keyboardLayoutNotValidated = !validators.requiredValidator(
        "keyboardLayout",
        formFieldData?.keyboardLayout === "Other" ? "" : formFieldData?.keyboardLayout
      );
    }

    if (
      nameNotValidated ||
      jobTitleNotValidated ||
      managerNotValidated ||
      fileNotValidated || 
      keyboardLayoutNotValidated
    ) {
      console.log("Data is not validated");
    } else {
      handleOnSubmit(useMultipart);
    }
  }
  function onFocusNoteTextField() {
      setNoteLabel("noteTextFieldLabel")
  }
  function onBlurNoteTextField() {
    setNoteLabel("noteOnBoardTextFieldPlaceHolder")
  }
  return (
    <>
      {apiData ? (
        <>
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label={t("nameTextFieldLabel")}
              placeholder={t("nameTextFieldPlaceHolder")}
              onChange={(event) => {
                validators.requiredValidator("name", event.target?.value) &&
                  validators.minLengthValidator("name", event.target?.value, 3);
                dispatch(
                  updateSubmitData({
                    key: "name",
                    value: event.target?.value,
                  })
                );
              }}
              value={formFieldData?.name ? formFieldData?.name : ""}
              required
              error={formErrorData?.name?.isError}
              helperText={
                formErrorData?.name?.isError &&
                t(formErrorData?.name?.errorMessage, { length: 3 })
              }
              InputLabelProps={{ className: "floating-label-focus-style" }}
            />
            <TextField
              fullWidth
              label={t("jobTitleTextFieldLabel")}
              placeholder={t("jobTitleTextFieldPlaceHolder")}
              onChange={(event) => {
                validators.requiredValidator("jobTitle", event.target.value);
                dispatch(
                  updateSubmitData({
                    key: "jobTitle",
                    value: event.target?.value,
                  })
                );
              }}
              value={formFieldData?.jobTitle ? formFieldData?.jobTitle : ""}
              required
              error={formErrorData?.jobTitle?.isError}
              helperText={
                formErrorData?.jobTitle?.isError &&
                t(formErrorData?.jobTitle?.errorMessage)
              }
            />
          </Stack>
          <Autocomplete
            id="tags-standard"
            options={uniqueUsers}
            filterSelectedOptions
            onChange={(event, value) => {
              validators.requiredValidator("manager", value);
              dispatch(updateSubmitData({ key: "manager", value: value }));
            }}
            value={formFieldData?.manager ? formFieldData?.manager : ""}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("managerSelectLabel")}
                placeholder={t("managerSelectPlaceHolder")}
                error={formErrorData?.manager?.isError}
                helperText={
                  formErrorData?.manager?.isError &&
                  t(formErrorData?.manager?.errorMessage)
                }
              />
            )}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              fullWidth
              label={t("phoneTextFieldLabel")}
              placeholder={t("phoneTextFieldPlaceHolder")}
              onChange={(event) =>
                dispatch(
                  updateSubmitData({
                    key: "phoneNumber",
                    value: event.target?.value,
                  })
                )
              }
              value={
                formFieldData?.phoneNumber ? formFieldData?.phoneNumber : ""
              }
            />
            <TextField
              fullWidth
              label={t("mobilePhoneTextFieldLabel")}
              placeholder={t("mobilePhoneTextFieldPlaceHolder")}
              onChange={(event) =>
                dispatch(
                  updateSubmitData({
                    key: "mobilePhoneNumber",
                    value: event.target?.value,
                  })
                )
              }
              value={
                formFieldData?.mobilePhoneNumber
                  ? formFieldData?.mobilePhoneNumber
                  : ""
              }
            />
          </Stack>

          <TextField
            fullWidth
            label={t("locationTextFieldLabel")}
            placeholder={t("locationTextFieldPlaceHolder")}
            onChange={(event) =>
              dispatch(
                updateSubmitData({
                  key: "location",
                  value: event.target?.value,
                })
              )
            }
            value={formFieldData?.location ? formFieldData?.location : ""}
          />
          <Stack direction="row" spacing={2}>
            <Autocomplete
              style={{ width: "100%" }}
              id="tags-standard"
              options={keyboardLayout}
              filterSelectedOptions
              onChange={(event, value) => {
                dispatch(
                  updateSubmitData({
                    key: "keyboardLayout",
                    value: value,
                  })
                );
                setKeyboardLayout(value);
              }}
              value={selectedKeyBoardLayout}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("keyboardLayoutTextFieldLabel")}
                  placeholder={t("keyboardLayoutTextFieldPlaceHolder")}
                />
              )}
            />
            {selectedKeyBoardLayout === "Other" ? (
              <TextField
                fullWidth
                label={t("otherKeyboardTextFieldLabel")}
                placeholder={t("otherKeyboardTextFieldPlaceHolder")}
                onChange={(event) => {
                  validators.requiredValidator("keyboardLayout", event.target?.value);
                  dispatch(
                    updateSubmitData({
                      key: "keyboardLayout",
                      value: event.target?.value,
                    })
                  );
                }}
                value={
                  formFieldData?.keyboardLayout &&
                  formFieldData?.keyboardLayout !== "Other"
                    ? formFieldData?.keyboardLayout
                    : ""
                }
                error={formErrorData?.keyboardLayout?.isError}
                helperText={
                  formErrorData?.keyboardLayout?.isError &&
                  t(formErrorData?.keyboardLayout?.errorMessage)
                }
              />
            ) : null}
          </Stack>
          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("startDateTextFieldLabel")}
                value={
                  formFieldData?.startDate
                    ? formFieldData?.startDate
                    : dayjs(new Date())
                }
                onChange={(value) => {
                  validators.requiredValidator("startDate", value);
                  dispatch(
                    updateSubmitData({ key: "startDate", value: value })
                  );
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    helperText:
                      formErrorData?.startDate?.isError &&
                      t(formErrorData?.startDate?.errorMessage),
                    error: formErrorData?.startDate?.isError,
                  },
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={t("endDateTextFieldLabel")}
                value={formFieldData?.endDate}
                onChange={(value) =>
                  dispatch(updateSubmitData({ key: "endDate", value: value }))
                }
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Stack>

          <Autocomplete
            multiple
            id="tags-standard"
            options={apiData.tenant.sharepointSites}
            filterSelectedOptions
            onChange={(event, value) =>
              dispatch(
                updateSubmitData({ key: "sharepointSites", value: value })
              )
            }
            value={
              formFieldData?.sharepointSites
                ? formFieldData?.sharepointSites
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("sharePointSiteTextFieldLabel")}
                placeholder={t("sharePointSiteTextFieldPlaceHolder")}
              />
            )}
          />
          <Autocomplete
            multiple
            id="tags-standard"
            options={apiData.tenant.distributionGroups}
            filterSelectedOptions
            onChange={(event, value) =>
              dispatch(
                updateSubmitData({ key: "distributionLists", value: value })
              )
            }
            value={
              formFieldData?.distributionLists
                ? formFieldData?.distributionLists
                : []
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("distributionListTextFieldLabel")}
                placeholder={t("sharePointSiteTextFieldPlaceHolder")}
              />
            )}
          />
          <TextField
            label={t(noteLabel)}
            placeholder={t("noteOnBoardTextFieldPlaceHolder")}
            multiline
            rows={5}
            onChange={(event) =>
              dispatch(
                updateSubmitData({ key: "note", value: event.target?.value })
              )
            }
            onFocus={onFocusNoteTextField}
            onBlur={onBlurNoteTextField}
            value={formFieldData?.note ? formFieldData?.note : ""}
          />
          {formFieldData?.attachment &&
          Array.from(formFieldData?.attachment).length >= 4 ? null : (
            <FileUploader
              multiple
              handleChange={(file) => {
                let totalSize = 0;
                for (const fileElement of [
                  ...file,
                  ...(formFieldData?.attachment
                    ? formFieldData?.attachment
                    : []),
                ]) {
                  totalSize = totalSize + fileElement.size;
                }
                validators.maxLengthValidator(
                  "attachment",
                  [
                    ...file,
                    ...(formFieldData?.attachment
                      ? formFieldData?.attachment
                      : []),
                  ],
                  4,
                  t("numberOfFileError")
                ) && validators.fileSizeValidator("attachment", totalSize);
                dispatch(
                  updateSubmitData({
                    key: "attachment",
                    value: [
                      ...file,
                      ...(formFieldData?.attachment
                        ? formFieldData?.attachment
                        : []),
                    ],
                  })
                );
              }}
              value={
                formFieldData?.attachment ? formFieldData?.attachment : null
              }
              label="Attachment"
              name="file"
              children={
                <FileFieldDisplayComponent
                  isError={formErrorData?.attachment?.isError}
                />
              }
            />
          )}

          {formFieldData?.attachment ? (
            <>
              <List sx={{ width: "100%", border: "1px" }}>
                {Array.from(formFieldData?.attachment).map((file, index) => (
                  <ListItem
                    disableGutters
                    secondaryAction={
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          const newFileList = Array.from(
                            formFieldData?.attachment
                          ).filter((item, index) => item != file);
                          let totalSize = 0;
                          for (const fileElement of newFileList) {
                            totalSize = totalSize + fileElement.size;
                          }
                          validators.maxLengthValidator(
                            "attachment",
                            newFileList,
                            4,
                            "numberOfFileError"
                          ) &&
                            validators.fileSizeValidator(
                              "attachment",
                              totalSize
                            );
                          dispatch(
                            updateSubmitData({
                              key: "attachment",
                              value: newFileList,
                            })
                          );
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    }
                    sx={{
                      border: "1px solid rgb(213, 218, 215)",
                      borderRadius: "5px",
                      marginBottom: "20px",
                    }}
                  >
                    <ListItemText
                      primary={file.name}
                      sx={{
                        marginLeft: "10px",
                      }}
                    />
                  </ListItem>
                ))}
                {formErrorData?.attachment?.isError ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "0.75rem",
                      textAlign: "left",
                    }}
                  >
                    *{t(formErrorData?.attachment?.errorMessage)}
                  </p>
                ) : null}
              </List>
            </>
          ) : null}

          <Button
            variant="contained"
            onClick={handleSubmitData}
            style={{
              marginBottom: "4vh",
              marginTop: "4vh",
              backgroundColor: "#8F201B",
              color: "#FFFFFF",
              borderRadius: "100px",
              textTransform: "none",
            }}
          >
            {t("submit")}
          </Button>
        </>
      ) : null}
    </>
  );
};
