import { InteractionRequiredAuthError } from '@azure/msal-common';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authScopes, protectedResources } from '../../authConfig';
import { callApi } from '../../helpers/call_api';
import { getIdToken } from '../../helpers/msal_auth';
import { EmbedConfig } from '../../models/EmbedConfig';
import {RootState} from "../store";

interface EmbedConfigState {
  embedConfig: EmbedConfig[] | null;
  hasPermission: boolean,
  loading: boolean;
}

const initialState: EmbedConfigState = {
  embedConfig: null,
  loading: false,
  hasPermission: false
};
export const getEmbedReportAsync = createAsyncThunk(
  "embedConfig/fetchEmbedReport",
  async (_, { rejectWithValue }) => {
    try {
      let idToken = await getIdToken(authScopes);
      let embedReportData = await callApi(
        idToken,
        protectedResources.supportApi + "/report",
        "GET"
      );
      return embedReportData;
    } catch (e) {
      var error = e;
      if (e instanceof InteractionRequiredAuthError) {
        error = new Error(
          JSON.stringify({
            status: 403,
            message: "InteractionRequiredAuthError",
          })
        );
      }
      try {
        _ = JSON.parse(error.message);
      } catch (objError) {
        if (objError instanceof SyntaxError) {
          error = new Error(
            JSON.stringify({
              status: 500,
              message: error.message,
            })
          );
        }
      }
      return rejectWithValue(error);
    }
  }
);

const embedConfigSlice = createSlice({
  name: 'embedConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmbedReportAsync.fulfilled, (state, action) => {
      if (Object.keys(action.payload).length === 0) {
        state.hasPermission = false
      } else {
        state.hasPermission = true
        state.embedConfig = action.payload
      }
      state.loading = false;
    });
    builder.addCase(getEmbedReportAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEmbedReportAsync.rejected, (state) => {
      state.loading = false;
    });
   },
});


export const loading = (state: RootState) => state.embedConfig.loading;

export const embedConfig = (state: RootState) => state.embedConfig.embedConfig;

export const hasReportPermission = (state: RootState) => state.embedConfig.hasPermission;

export default embedConfigSlice.reducer;