import React, { useEffect, useRef } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import type { EmbedConfig } from '../models/EmbedConfig';
import '../styles/css/SupersetViewer.css';  // Import CSS file

interface SupersetViewerProps {
  config: EmbedConfig;
}

const SupersetViewer: React.FC<SupersetViewerProps> = ({ config }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const initializeSuperset = async () => {
      try {
        await embedDashboard({
          id: config.uuid,
          supersetDomain: config.url,
          mountPoint: containerRef.current,
          fetchGuestToken: () => Promise.resolve(config.token),
          dashboardUiConfig: {
            hideTitle: true,
            hideTab: false,
            hideChartControls: true,
            filters: {
              expanded: false
            }
          },
        });
      } catch (error) {
        console.error('Error initializing Superset dashboard:', error);
      }
    };

    initializeSuperset();

    // Cleanup function
    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
    };
  }, [config]);

  return (
    <div
      ref={containerRef}
      className="superset-container"
    />
  );
};

export default SupersetViewer;