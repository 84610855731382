import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { FormProps } from "./FormProps";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { dataFromApi } from "../../redux/getApiData/reducer";
import {
  errorData,
  submitData,
  updateSubmitData,
} from "../../redux/submitData/reducer";
import { ManageSharePointSiteFormData } from "../../models/forms/ManageSharePointSiteFormData";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

export const ManageSiteUserForm: React.FC<FormProps> = ({
  handleOnSubmit,
  validators,
}) => {
  const apiData = useAppSelector(dataFromApi);
  const dispatch = useAppDispatch();
  const formData = useAppSelector(submitData);
  const formFieldData = formData?.data as ManageSharePointSiteFormData;
  const formErrorData = useAppSelector(errorData);
  const uniqueUsers = apiData?Array.from(new Set(apiData.tenant.users)) : [];
  const levelOfPermission: { level: string; description: string }[] = [
    { level: "ownerLevel", description: "ownerLevelDesciption" },
    { level: "memberLevel", description: "memberLevelDesciption" },
    { level: "visitorLevel", description: "visitorLevelDesciption" },
  ];
  const { t } = useTranslation();
  function handleSubmitData() {
    var sharepointSiteNotValidated = !validators.requiredValidator(
      "sharepointSite",
      formFieldData?.sharepointSite
    );
    var usernameNotValidated = !validators.minLengthValidator(
      "username",
      formFieldData?.username,
      1,
      "requiredError"
    );
    var levelOfPermissionNotValidated = !validators.requiredValidator(
      "levelOfPermission",
      formFieldData?.levelOfPermission
    );

    if (
      sharepointSiteNotValidated ||
      usernameNotValidated ||
      levelOfPermissionNotValidated
    ) {
      console.log("Data is not validated");
    } else {
      handleOnSubmit(false);
    }
  }
  return (
    <>
      {apiData ? (
        <>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={formFieldData?.action}
              onChange={(event, value) =>
                dispatch(updateSubmitData({ key: "action", value: value }))
              }
              sx={{ justifyContent: "center" }}
            >
              <FormControlLabel
                value="add"
                control={<Radio color="secondary" />}
                label={t("add")}
                sx={{ paddingRight: "10vh" }}
              />
              <FormControlLabel
                value="remove"
                control={<Radio color="secondary" />}
                label={t("remove")}
              />
            </RadioGroup>
          </FormControl>
          <Autocomplete
            id="tags-standard"
            options={apiData.tenant.sharepointSites}
            filterSelectedOptions
            onChange={(event, value) => {
              validators.requiredValidator("sharepointSite", value);
              dispatch(
                updateSubmitData({ key: "sharepointSite", value: value })
              );
            }}
            value={
              formFieldData?.sharepointSite ? formFieldData?.sharepointSite : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("sharePointSiteTextFieldLabel")}
                placeholder={t("sharePointSiteTextFieldPlaceHolder")}
                error={formErrorData?.sharepointSite?.isError}
                helperText={
                  formErrorData?.sharepointSite?.isError &&
                  t(formErrorData?.sharepointSite?.errorMessage)
                }
              />
            )}
          />
          <Autocomplete
            multiple

            id="tags-standard"
            options={uniqueUsers}
            filterSelectedOptions
            value={formFieldData?.username ? formFieldData?.username : []}
            onChange={(event, value) => {
              validators.minLengthValidator(
                "username",
                value,
                1,
                "requiredError"
              )
              dispatch(updateSubmitData({ key: "username", value: value }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("usernameTextFieldLabel")}
                required
                placeholder={t("usernameTextFieldPlaceHolder")}
                error={formErrorData?.username?.isError}
                helperText={
                  formErrorData?.username?.isError &&
                  t(formErrorData?.username?.errorMessage)
                }
              />
            )}
          />
          <Autocomplete
            id="tags-standard"
            options={levelOfPermission}
            filterSelectedOptions
            value={
              formFieldData?.levelOfPermission
                ? levelOfPermission.find((level) => {
                    return level.level === formFieldData?.levelOfPermission;
                  })
                : null
            }
            autoHighlight
            getOptionLabel={(option) => (option ? t(option.level) : null)}
            onChange={(event, value) => {
              validators.requiredValidator("levelOfPermission", value?.level);
              dispatch(
                updateSubmitData({
                  key: "levelOfPermission",
                  value: value?.level,
                })
              );
            }}
            renderOption={(props, option) => {
              const { ...optionProps } = props;
              return (
                <>
                  <li {...props} style={{ padding: "8px", textAlign: "left" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start", // Align items to the left
                        width: "100%", // Ensure full width
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(29, 27, 32, 1)",
                          fontSize: "16px",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          textAlign: "left",
                          padding: "5px 15px 2px",
                        }}
                      >
                        {t(option.level)}
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(73, 69, 79, 1)",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          textAlign: "left",
                          padding: "5px 15px 2px",
                        }}
                      >
                        {t(option.description)}
                      </Typography>
                    </Box>
                  </li>
                  {/* <Box component="li" {...props} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 1, textAlign: "left" }}>
                      <Typography
                        sx={{
                          color: "rgba(29, 27, 32, 1)",
                          fontSize: "16px",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          textAlign: "left",
                          padding: "5px 15px 2px",
                        }}
                      >
                        {t(option.level)}
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(73, 69, 79, 1)",
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          fontWeight: "400",
                          textAlign: "left",
                          padding: "5px 15px 2px",
                        }}
                      >
                        {t(option.description)}
                      </Typography>
                  </Box> */}
                </>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("levelOfPermissionTextFieldLabel")}
                placeholder={t("levelOfPermissionTextFieldPlaceHolder")}
                error={formErrorData?.levelOfPermission?.isError}
                helperText={
                  formErrorData?.levelOfPermission?.isError &&
                  t(formErrorData?.levelOfPermission?.errorMessage)
                }
              />
            )}
          />
          <TextField
            label={t("noteTextFieldLabel")}
            placeholder={t("noteTextFieldPlaceHolder")}
            multiline
            rows={5}
            onChange={(event) =>
              dispatch(
                updateSubmitData({ key: "note", value: event.target?.value })
              )
            }
            value={formFieldData?.note ? formFieldData?.note : ""}
          />
          <Button
            variant="contained"
            onClick={handleSubmitData}
            style={{
              marginBottom: "4vh",
              marginTop: "4vh",
              backgroundColor: "#8F201B",
              color: "#FFFFFF",
              borderRadius: "100px",
              textTransform: "none",
            }}
          >
            {t("submit")}
          </Button>
        </>
      ) : null}
    </>
  );
};
