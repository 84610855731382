import { SvgIconComponent } from "@mui/icons-material";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { FORM_TYPE, ROUTES } from "../constant";

const sideBar: {formSideBar: { title: string, route: string, icon: SvgIconComponent }[], reportSideBar: { title: string, route: string, icon: SvgIconComponent }[]} = {
    formSideBar: [
        
        {
            "title": FORM_TYPE.ONBOARD_EMPLOYEE.TITLE,
            "route": ROUTES.FORMS + "?type=" + FORM_TYPE.ONBOARD_EMPLOYEE.TYPE,
            "icon": FORM_TYPE.ONBOARD_EMPLOYEE.ICON
        },
        {
            "title": FORM_TYPE.OFFBOARD_EMPLOYEE.TITLE,
            "route": ROUTES.FORMS + "?type=" + FORM_TYPE.OFFBOARD_EMPLOYEE.TYPE,
            "icon": FORM_TYPE.OFFBOARD_EMPLOYEE.ICON
        },
        {
            "title": FORM_TYPE.MANAGE_SITE_USERS.TITLE,
            "route": ROUTES.FORMS + "?type=" + FORM_TYPE.MANAGE_SITE_USERS.TYPE,
            "icon": FORM_TYPE.MANAGE_SITE_USERS.ICON
        },
        {
            "title": FORM_TYPE.MANAGE_SHARED_MAILBOXES.TITLE,
            "route": ROUTES.FORMS + "?type=" + FORM_TYPE.MANAGE_SHARED_MAILBOXES.TYPE,
            "icon": FORM_TYPE.MANAGE_SHARED_MAILBOXES.ICON
        },
        {
            "title": FORM_TYPE.NEW_SITE_TEAM.TITLE,
            "route": ROUTES.FORMS + "?type=" + FORM_TYPE.NEW_SITE_TEAM.TYPE,
            "icon": FORM_TYPE.NEW_SITE_TEAM.ICON
        },
        {
            "title": FORM_TYPE.OTHER_REQUEST.TITLE,
            "route": ROUTES.FORMS + "?type=" + FORM_TYPE.OTHER_REQUEST.TYPE,
            "icon": FORM_TYPE.OTHER_REQUEST.ICON
        }
    
    ],
    reportSideBar: [
        {
            "title": "reportTitle",
            "route": ROUTES.REPORTS,
            "icon": AssessmentOutlinedIcon
        },
        {
            "title": "Security Dashboard",
            "route": ROUTES.SECURITYDASHBOARD,
            "icon": AssessmentOutlinedIcon
        },
        {
            "title": "License Dashboard",
            "route": ROUTES.LICENSEDASHBOARD,
            "icon": AssessmentOutlinedIcon
        },
        {
            "title": "Identity Dashboard",
            "route": ROUTES.IDENTITYDASHBOARD,
            "icon": AssessmentOutlinedIcon
        },
        {
            "title": "Email Dashboard",
            "route": ROUTES.SUPERSETDASHBOARD,
            "icon": AssessmentOutlinedIcon
        },
        {
            "title": "CIS Dashboard",
            "route": ROUTES.CISDASBOARD,
            "icon": AssessmentOutlinedIcon
        }
    ]
}

export default sideBar;
