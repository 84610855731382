import React from 'react';
import SupersetViewer from '../components/SupersetViewer';
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EmbedConfig } from "../models/EmbedConfig";

type SupersetReportProps = {
  config: EmbedConfig;
};

export const SupersetReport: React.FC<SupersetReportProps> = ({ config }) => {
  const { t } = useTranslation();

  return <SupersetViewer config={config} />;  // Pass config directly
};