import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { NewSiteTeamRequestFormData } from "../../models/forms/NewSiteTeamRequestFormData";
import { dataFromApi } from "../../redux/getApiData/reducer";
import {
  errorData,
  submitData,
  updateSubmitData,
} from "../../redux/submitData/reducer";
import { FormProps } from "./FormProps";
import InfoIcon from "@mui/icons-material/Info";

export const NewSiteTeamRequestForm: React.FC<FormProps> = ({
  handleOnSubmit,
  validators,
}) => {
  const apiData = useAppSelector(dataFromApi);
  const dispatch = useAppDispatch();
  const formData = useAppSelector(submitData);
  const formFieldData = formData?.data as NewSiteTeamRequestFormData;
  const uniqueUsers = apiData?Array.from(new Set(apiData.tenant.users)) : [];
  const formErrorData = useAppSelector(errorData);
  const { t } = useTranslation();
  function handleSubmitData() {
    var nameNotValidated = !validators.requiredValidator(
      "name",
      formFieldData?.name
    );
    var ownerNotValidated = false;
    if (formFieldData?.siteOrTeam === "site") {
      ownerNotValidated = !validators.minLengthValidator(
        "owner",
        formFieldData?.owner,
        1,
        "requiredError"
      );
    }
    if (formFieldData?.siteOrTeam === "team") {
      if (!formFieldData?.visibility) {
        dispatch(updateSubmitData({ key: "visibility", value: "private" }));
      }
      ownerNotValidated = !(
        validators.minLengthValidator(
          "owner",
          formFieldData?.owner,
          1,
          "requiredError"
        ) &&
        validators.minLengthValidator(
          "owner",
          formFieldData?.owner,
          2,
          "ownerError"
        )
      );
    }

    if (nameNotValidated || ownerNotValidated) {
      console.log("Data is not validated");
    } else {
      handleOnSubmit(false);
    }
  }
  return (
    <>
      {apiData ? (
        <>
          <FormControl required>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event, value) => {
                dispatch(updateSubmitData({ key: "siteOrTeam", value: value }));
                if (value === "site") {
                  dispatch(updateSubmitData({ key: "visibility", value: "", delete: true }));
                }
                if (formFieldData?.owner?.length > 0) {
                  if (value === "site") {
                    validators.minLengthValidator(
                      "owner",
                      formFieldData?.owner,
                      1,
                      "requiredError"
                    );
                  }
                  if (value === "team") {
                    validators.minLengthValidator(
                      "owner",
                      formFieldData?.owner,
                      1,
                      "requiredError"
                    ) &&
                      validators.minLengthValidator(
                        "owner",
                        formFieldData?.owner,
                        2,
                        "ownerError"
                      );
                  }
                }
              }}
              value={
                formFieldData?.siteOrTeam ? formFieldData?.siteOrTeam : "site"
              }
              sx={{ justifyContent: "center" }}
            >
              <FormControlLabel
                value="site"
                control={<Radio color="secondary" />}
                label={t("site")}
                sx={{ paddingRight: "10vh" }}
              />
              <FormControlLabel
                value="team"
                control={<Radio color="secondary" />}
                label={t("team")}
              />
            </RadioGroup>
          </FormControl>
          <TextField
            required
            label={t("nameTextFieldLabel")}
            placeholder={t("nameTextFieldPlaceHolder")}
            onChange={(event) => {
              validators.requiredValidator("name", event.target?.value);
              dispatch(
                updateSubmitData({ key: "name", value: event.target?.value })
              );
            }}
            value={formFieldData?.name ? formFieldData?.name : ""}
            helperText={
              formErrorData?.name?.isError &&
              t(formErrorData?.name?.errorMessage)
            }
            error={formErrorData?.name?.isError}
          />
          <Autocomplete
            multiple
            id="tags-standard"
            options={uniqueUsers}
            filterSelectedOptions
            onChange={(event, value) => {
              if (formFieldData?.siteOrTeam === "site") {
                validators.minLengthValidator(
                  "owner",
                  value,
                  1,
                  "requiredError"
                );
              }
              if (formFieldData?.siteOrTeam === "team") {
                validators.minLengthValidator(
                  "owner",
                  value,
                  1,
                  "requiredError"
                ) &&
                  validators.minLengthValidator(
                    "owner",
                    value,
                    2,
                    "ownerError"
                  );
              }

              dispatch(updateSubmitData({ key: "owner", value: value }));
            }}
            value={formFieldData?.owner ? formFieldData?.owner : []}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("ownerTextFieldLabel")}
                placeholder={t("ownerTextFieldPlaceHolder")}
                required
                helperText={
                  formErrorData?.owner?.isError
                    ? t(formErrorData?.owner?.errorMessage, { length: 2 })
                    : t("ownerHelptext")
                }
                error={formErrorData?.owner?.isError}
              />
            )}
          />
          <Autocomplete
            multiple
            id="tags-standard"
            options={uniqueUsers}
            onChange={(event, value) =>
              dispatch(updateSubmitData({ key: "members", value: value }))
            }
            filterSelectedOptions
            value={formFieldData?.members ? formFieldData?.members : []}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("memberTextFieldLabel")}
                placeholder={t("memberTextFieldPlaceHolder")}
                helperText={t("memberHelptext")}
              />
            )}
          />
          {formFieldData?.siteOrTeam === "team" ? (
            <FormControl required>
              <FormLabel
                id="demo-radio-buttons-group-label"
                style={{ textAlign: "left" }}
              >
                {t("visibility")}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(event, value) => {
                  dispatch(
                    updateSubmitData({ key: "visibility", value: value })
                  );
                }}
                value={
                  formFieldData?.visibility
                    ? formFieldData?.visibility
                    : "private"
                }
              >
                <FormControlLabel
                  value="private"
                  control={<Radio color="secondary" />}
                  label={
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 4 }}
                    >
                      <Typography variant="body2">{t("private")}</Typography>
                      <Tooltip title={t("privateHelpText")} placement="top">
                        <InfoIcon fontSize="small" color="action" />
                      </Tooltip>
                    </div>
                  }
                />
                <FormControlLabel
                  value="public"
                  control={<Radio color="secondary" />}
                  label={<div
                    style={{ display: "flex", alignItems: "center", gap: 4 }}
                  >
                    <Typography variant="body2">{t("public")}</Typography>
                    <Tooltip title={t("publicHelpText")} placement="top">
                      <InfoIcon fontSize="small" color="action" />
                    </Tooltip>
                  </div>}
                />
              </RadioGroup>
            </FormControl>
          ) : null}
          <TextField
            label={t("noteTextFieldLabel")}
            placeholder={t("noteTextFieldPlaceHolder")}
            multiline
            rows={5}
            onChange={(event) => {
              dispatch(
                updateSubmitData({ key: "note", value: event.target?.value })
              );
            }}
            value={formFieldData?.note ? formFieldData?.note : ""}
          />
          <Button
            variant="contained"
            onClick={handleSubmitData}
            style={{
              marginBottom: "4vh",
              marginTop: "4vh",
              backgroundColor: "#8F201B",
              color: "#FFFFFF",
              borderRadius: "100px",
              textTransform: "none",
            }}
          >
            {t("submit")}
          </Button>
        </>
      ) : null}
    </>
  );
};
