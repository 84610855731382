import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authScopes, protectedResources } from "../../authConfig";
import { callApi } from "../../helpers/call_api";
import { getIdToken } from "../../helpers/msal_auth";
import { ApiData } from "../../models/ApiData";
import { RootState } from "../store";
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-common";

export interface DataState {
  data: ApiData | null;
  isLoading: boolean;
}

const initialState: DataState = {
  data: null,
  isLoading: false,
};

const sortAz = (array: string[] | undefined): string[] => {
  if (!array) return [];
  return [...array].sort((a, b) => a.localeCompare(b));
};

export const getDataAsync = createAsyncThunk(
  "data/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      let idToken = await getIdToken(authScopes);
      let apiData = await callApi(
        idToken,
        protectedResources.supportApi + "/data",
        "GET"
      );
      return apiData;
    } catch (e) {
      var error = e;
      if (e instanceof InteractionRequiredAuthError) {
        error = new Error(
          JSON.stringify({
            status: 403,
            message: "InteractionRequiredAuthError",
          })
        );
      }
      try {
        _ = JSON.parse(error.message);
      } catch (objError) {
        if (objError instanceof SyntaxError) {
          error = new Error(
            JSON.stringify({
              status: 500,
              message: error.message,
            })
          );
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDataAsync.fulfilled, (state, action) => {
      state.data = {
        name: action.payload?.name,
        tenant: {
          distributionGroups: sortAz(action.payload?.tenant.distribution_groups),
          groups: sortAz(action.payload?.tenant.groups),
          licenses: sortAz(action.payload?.tenant.licenses),
          sharedMailboxes: sortAz(action.payload?.tenant.shared_mailboxes),
          sharepointSites: sortAz(action.payload?.tenant.sharepoint_sites),
          teams: sortAz(action.payload?.tenant.teams),
          users: sortAz(action.payload?.tenant.users),
        },
      };
      state.isLoading = false;
    });
    builder.addCase(getDataAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDataAsync.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const dataFromApi = (state: RootState) => state.apiData.data;

export const isDataLoading = (state: RootState) => state.apiData.isLoading;

export default dataSlice.reducer;
