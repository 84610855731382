import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../hooks/hook';
import { loading, embedConfig } from '../redux/getEmbedConfig/reducer';
import { Loading } from "../components/Loading";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PowerBIReport } from './PowerBIReport';
import { SupersetReport } from './SupersetReport';
import { Box } from '@mui/material';

export const GenericReport = () => {
  const { title } = useParams();
  const isLoading = useAppSelector(loading);
  const configs = useAppSelector(embedConfig);
  const { t } = useTranslation();

  // Find the matching config for this report
  const reportConfig = configs?.find(config => config.title === title);

  if (isLoading) {
    return <Loading />;
  }

  if (!reportConfig) {
    return <Typography>{t("cannotLoadReport")}</Typography>;
  }

  switch (reportConfig.type) {
    case 'powerbi':
      return <PowerBIReport config={reportConfig} />;
    case 'superset':
      return <SupersetReport config={reportConfig} />;
    default:
      return <Typography>{t("unsupportedReportType")}</Typography>;
  }
};